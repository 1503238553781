import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import store from "../store";
import axios from "axios";
Vue.use(VueRouter);

const router = new VueRouter({
  scrollBehavior: () => ({ x: 0, y: 0 }),
  routes,
  mode: "history",
  base: process.env.BASE_URL,
});

router.beforeEach(async (to, from, next) => {
  let { data } = await axios.get("admins/details/profile");
  if (data) {
    store.dispatch("setProfile", data);
    store.dispatch("setRole", data.admin.role);
  }
  const isLogin = localStorage.getItem("inginsia-loggedIn");

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (isLogin) {
      if (to.name == "Admins") {
        console.log("to", to.name);
        console.log("store", store.state.role);
        if (store.state.role == 0) {
          next();
        } else {
          next({ path: "/" });
        }
      } else {
        next();
      }
    } else {
      next({
        path: "/login",
      });
    }
  } else {
    if (isLogin && to.matched.some((record) => record.meta.auth)) {
      // next(() => {
      //   if(store.state.role !=  0){
      //     next('/')
      //   }
      //   // access to component public instance via `vm`
      // });
      next({
        path: "/",
      });
    } else {
      next();
    }
  }
});

export default router;
