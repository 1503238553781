import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "./plugins/axios";
// scss
import "./assets/style/main.scss";

// helper
import helper from "./utils/helper";
// base
import BaseInput from "@/components/base/Input";
import TooltipIcon from "@/components/base/TooltipIcon";
import BaseSelect from "@/components/base/Select";
import BaseAutocomplete from "@/components/base/AutoComplete";
import BaseTextArea from "@/components/base/Textarea";
import BaseDate from "@/components/base/Date";
import UploadAvatar from "@/components/base/UploadAvatar";
import UploadAttachment from "@/components/base/UploadAttachment";
import DeleteConfirm from "./components/dialogs/DeleteConfirm.vue";
import draggable from "vuedraggable";
import { mapGetters } from "vuex";
import i18n from "./i18n";

Vue.config.productionTip = false;
Vue.component("tooltip-icon", TooltipIcon);
Vue.component("base-input", BaseInput);
Vue.component("base-autocomplete", BaseAutocomplete);
Vue.component("base-select", BaseSelect);
Vue.component("base-textarea", BaseTextArea);
Vue.component("base-date", BaseDate);
Vue.component("upload-avatar", UploadAvatar);
Vue.component("upload-attachment", UploadAttachment);
Vue.component("delete-confirm", DeleteConfirm);
Vue.component("draggable", draggable);

Vue.prototype.$helper = helper;
Vue.prototype.$defaultImage =
  "http://www.gergitavan.com/wp-content/uploads/2017/07/default-placeholder-1024x1024-570x321.png";
Vue.use(axios);
Vue.use(require("vue-moment"));

Vue.mixin({
  computed: {
    ...mapGetters(["role"]),
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    isMd() {
      return (
        this.$vuetify.breakpoint.xs ||
        this.$vuetify.breakpoint.sm ||
        this.$vuetify.breakpoint.md
      );
    },
    isLg() {
      return (
        this.$vuetify.breakpoint.xs ||
        this.$vuetify.breakpoint.sm ||
        this.$vuetify.breakpoint.md ||
        this.$vuetify.breakpoint.lg
      );
    },
  },
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
