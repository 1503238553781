export default {
  sidebar: {
    projects: "Projects",
    suppliers: "Suppliers",
    users: "Users",
    admins: "Admins",
    allTasks: "All Tasks",
    reports: "Reports",
    archive: "Archive",
    notifications: "Notifications",
    sendEmails: "Send Emails",
    jobs: "Jobs",
    settings: "Settings",
    legalAnnoncements: "Legal annoncements",
    logout: "Logout",
  },
  projectPage: {
    imoprtCSV: "Import CSV From Asana",
    importJson: "Import JSON From Asana",
    newProject: "New Project",
    hello: "Hello",
    sendToArchive: "Send To Archive",
  },
};
