<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-icon color="cursor-pointer" @click="$emit('click')" size="18" v-bind="attrs" v-on="on">
        {{ icon }}
      </v-icon>
    </template>
    <slot />
    <span>{{ text }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
  },
};
</script>

<style></style>
