<template>
  <v-app>
    <div class="app-cover" v-if="!$route.meta.auth"></div>
    <router-view></router-view>
    <div class="loading" v-if="loading">
      <div class="text-center">
        <img
          class="object-contain"
          width="130px"
          :src="require('@/assets/images/logos/logo-tag-light.png')"
        />
        <span class="white--text d-block mt-2">Loading...</span>
      </div>
    </div>
    <snackbar></snackbar>
  </v-app>
</template>

<script>
import Snackbar from "@/components/base/Snackbar";
import { mapGetters } from "vuex";
export default {
  name: "App",
  components: {
    Snackbar,
  },
  data: () => ({}),
  computed: {
    ...mapGetters({
      loading: "loading",
    }),
  },
};
// async downloadFile(uri) {
//   let file = await axios.get(uri, {
//     responseType: "blob",
//     headers: {
//       authorization: "Bearer " + localStorage.getItem("accessToken")
//     }
//   });
//   return file;
// }
</script>

<style lang="scss">
.app-cover {
  background: url("./assets/images/bg.jpg") center no-repeat !important;
  background-size: cover !important;
  width: calc(100% - 250px);
  right: 0px;
  position: absolute;
  height: 100%;

  @media (max-width: 960px) {
    width: 100% !important;
  }
}

.loading {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99999999999999999999999999999999;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  animation: fade 1s forwards;
}
.minus-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
  background: $primary;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@keyframes fade {
  to {
    opacity: 1;
  }
}
</style>
