const routes = [
  {
    path: "/login",
    name: "Auth",
    component: () => import("../views/auth/Login.vue"),
    meta: { auth: true },
  },
  {
    path: "/signup",
    name: "Auth",
    component: () => import("../views/auth/Signup.vue"),
    meta: { auth: true },
  },
  {
    path: "/",
    component: () => import("../layout/MainLayout.vue"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "/",
        name: 'Projects',
        component: () => import("../views/projects/Index.vue"),
      },
      {
        path: "/projects",
        name: 'Projects',
        component: () => import("../views/projects/Index.vue"),
      },
      {
        path: "/project-columns/:id",
        component: () => import("../views/sections/columns/Index.vue"),
        name: "sectionsPage",
        children: [
          {
            path: "/project/:id/:taskId",
            component: () => import("../views/task/Index.vue"),
            name: "taskPage",
          },
        ],
      },
      {
        path: "/project-list/:id",
        component: () => import("../views/sections/list/Index.vue"),
        name: "sectionsPage",
        children: [
          {
            path: "/project-list/:id/:taskId",
            component: () => import("../views/task/Index.vue"),
            name: "taskPageList",
          },
        ],
      },
      {
        path: "/suppliers",
        name: 'Suppliers',
        component: () => import("../views/suppliers/Index.vue"),
      },
      {
        path: "/users",
        name: 'Users',
        component: () => import("../views/users/Index.vue"),
      },
      {
        path: "/admins",
        name: 'Admins',
        component: () => import("../views/admins/Index.vue"),
      },
      {
        path: "/all-tasks",
        name: 'Tasks',
        component: () => import("../views/all-tasks/Index.vue"),
        children: [
          {
            path: "/all-tasks/:id",
            component: () => import("../views/task/Index.vue"),
            name: "taskPage",
          },
        ],
      },
      {
        path: "/send-emails",
        name: 'Emails',
        component: () => import("../views/emails/Index.vue"),
      },
      {
        path: "/settings",
        name: 'Settings',
        component: () => import("../views/settings/Index.vue"),
      },
      {
        path: "/notifications",
        name: 'Notifications',
        component: () => import("../views/notifications/Index.vue"),
        children: [
          {
            path: "/notifications/:id",
            component: () => import("../views/task/Index.vue"),
            name: "notifications",
          },
        ],
      },
      {
        path: "/reports",
        name: 'Reports',
        component: () => import("../views/reports/Index.vue"),
        children: [
          {
            path: "/reports/:id",
            component: () => import("../views/task/Index.vue"),
            name: "reportsPage",
          },
        ],
      },
      {
        path: "/legal-annoncements",
        name: 'Legal-annoncements',
        component: () => import("../views/policy/Index.vue"),
      },
      {
        path: "/jobs",
        name: 'Jobs',
        component: () => import("../views/jobs/Index.vue"),
      },
      {
        path: "/archive",
        name: 'Archive',
        component: () => import("../views/archive/Index.vue"),
        children: [
          {
            path: "/archive/:id",
            component: () => import("../views/task/Index.vue"),
            name: "archive page",
          },
        ],
      },
    ],
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: "*",
    component: () => import("../views/Error404.vue"),
  },
];

export default routes;
