<template>
    <div class="text-center white px-7 py-3">
      <p class="priamry--text">Are You Sure Want To Delete ?</p>
      <div class="d-flex align-center justify-space-between mt-3">
        <v-btn color="gray" @click="$emit('closeDialog')">No</v-btn>
        <v-btn @click="$emit('confirmAccess')">Yes</v-btn>
      </div>
    </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
  },
  methods: {
    async deleteHandler() {
      const res = await this.$axios.delete(`/suppliers/${this.itemID._id}`);
      if (res && res.data) {
        this.$store.dispatch("showSnack", {
          text: "deleted successfully",
          color: "success",
        });
        await this.fetchData();
        this.$emit("deletedHandler");
      } else {
        this.$store.dispatch("showSnack", {
          text: res.error,
          color: "error",
        });
      }
    },
  },
};
</script>

<style></style>
