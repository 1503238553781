<template>
  <div class="custom-date">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="auto"
      dark
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="date"
          color="lightdark"
          prepend-inner-icon="mdi-calendar"
          readonly
          :label="placeholder"
          v-bind="attrs"
          v-on="on"
          :dark="false"
          :hide-details="true"
          dense
          outlined
        ></v-text-field>
      </template>
      <v-date-picker
      :range="isRange"
        :value="value"
        :readonly="readOnly"
        @input="[$emit('input', $event), (menu = false), (date = $event)]"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      validator: () => true,
    },
    placeholder: {
      type: String,
      default: "",
    },
    readOnly:{
      type: Boolean,
      default: false
    },
    isRange:{
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    date: "",
    menu: false,
  }),
  computed: {
    darkMood() {
      return this.$store.getters["darkMood"];
    },
  },
};
</script>

<style></style>
