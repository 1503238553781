export default {
    sidebar: {
      projects: "Mes Immeubles",
      suppliers: "Fournisseurs",
      users: "Conseil Syndical",
      admins: "Administration",
      allTasks: "Taches En Cours",
      reports: "Timeline",
      archive: "Archive",
      notifications: "Nouvea",
      sendEmails: "Actions",
      jobs: "Jobs",
      settings: "Parametres",
      legalAnnoncements: "Legal Annoncements",
      logout: "Quitter",
    },
    projectPage: {
      imoprtCSV: "Import CSV From Asana",
      importJson: "Import JSON From Asana",
      newProject: "New Project",
      hello: "Hello",
      sendToArchive: "Send To Archive",
    },
  };
  