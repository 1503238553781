<template>
  <div class="upload-image">
    <img :src="newImage ? newImage : itemImage" alt="" />

    <div class="upload-btn cursor-pointer">
      <v-icon color="grey">mdi-upload</v-icon>
      <span class="font-15 grey--text">Upload Logo</span>
      <v-file-input
        accept="image/*"
        label="File input"
        @change="newImageHandler"
        class="file-upload"
        hide-details="true"
      ></v-file-input>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    itemImage: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    newImage: "",
  }),
  methods: {
    newImageHandler(file) {
      this.newImage = URL.createObjectURL(file);
      this.$emit("uploadFile", file);
    },
  },
};
</script>

<style lang="scss">
.upload-image {
  width: 100%;
  height: 150px;
  border-radius: 7px;
  overflow: hidden;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .upload-btn {
    position: absolute;
    bottom: 20px;
    left: 20px;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.5);
    padding: 5px 10px;

    .file-upload {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 9;
      margin: 0px !important;
      padding: 0px !important;

      label {
        display: none !important;
      }
    }
  }
}
</style>
