<template>
  <div class="base-input base-textarea">
    <v-textarea
      :dark="false"
      color="lightdark"
      outlined
      :placeholder="placeholder"
      :rows="rows"
      dense
      hide-details="true"
      :rules="rules"
      :value="value"
      :readonly="readOnly"
      :auto-grow="false"
      no-resize
      @input="$emit('input', $event)"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
    >
    </v-textarea>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    value: {
      validator: () => true,
    },
    rows: {
      type: Number,
      default: 3,
    },
    rules: {
      type: Array,
      default: () => [(v) => !!v || "this field is require"],
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.base-textarea {
  .v-input__slot {
    fieldset {
      border: 1px solid #e2e5ed !important;
    }
    .v-input__append-inner {
      margin-top: 5px !important;
    }
    textarea {
      &::-webkit-scrollbar {
        width: 5px;
        height: 200px;
        background: rgb(231, 231, 231);
      }
      &::-webkit-scrollbar-thumb {
        background: rgb(173, 173, 173);
        border-radius: 10px;
      }
    }
  }
  .error--text{
    fieldset{
      border: 1px solid #ff6161 !important;

    }
  }
}
</style>
