import Vue from "vue";
import Vuex from "vuex";
import router from "../router";
import axios from 'axios'
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    text: "",
    color: "",
    timeout: "",
    loading: false,
    darkMood: false,
    role: '',
    notifications: [],
    profile: {}
  },
  getters: {
    getLoading(state) {
      return state.loading;
    },
    loading(state) {
      return state.loading
    },
    darkMood(state) {
      return state.darkMood
    },
    role(state) {
      return state.role
    },
    notifications(state) {
      return state.notifications
    },
    profile(state) {
      return state.profile
    }
  },
  mutations: {
    SHOW_MESSAGE(state, payload) {
      state.text = payload.text;
      state.color = payload.color;
      state.timeout = payload.timeout;
    },
    TOGGLE_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_USER(_, payload) {
      localStorage.setItem('token', payload.token)
      localStorage.setItem('inginsia-loggedIn', true)
      console.log('projectss')
      router.go('/projects')
    },
    REMOVE_USER() {
      localStorage.removeItem('token')
      localStorage.removeItem('inginsia-loggedIn')
      router.go()
    },
    SET_LOADING(state, payload) {
      state.loading = payload
    },
    SET_DARKMOOD(state, payload) {
      state.darkMood = payload
    },
    SET_ROLE(state, payload){
      state.role = payload
    },
    SET_NOTIFICATIONS(state, payload){
      state.notifications = payload
    },
    SET_PROFILE(state, payload){
      state.profile = payload
    }
  },
  actions: {
    showSnack({ commit }, payload) {
      commit("SHOW_MESSAGE", payload);
    },
    toggleLoading({ commit }, payload) {
      commit("TOGGLE_LOADING", payload);
    },
    setUser({ commit }, payload) {
      commit("SET_USER", payload);
    },
    logout({ commit }) {
      commit("REMOVE_USER");
    },
    loading({ commit }, payload) {
      commit('SET_LOADING', payload)
    },
    darkMoodToggle({ commit }, payload) {
      commit('SET_DARKMOOD', payload)
    },
    async assignToHandler(_, payload) {
      await axios.put(`tasks/${payload.taskID}`, {
        assignedTo: payload.userID
      });
    },
    async statusHandler(_, payload) {
      await axios.post(`tasks/next/status`, {
        task: payload.taskID
      });
    },
    setRole({commit}, payload) {
      commit('SET_ROLE', payload)
    },
    setProfile({commit}, payload) {
      commit('SET_PROFILE', payload)
    },
    setNotifications({commit}, payload) {
      commit('SET_NOTIFICATIONS', payload)
    },
    downloadFile({ commit }, payload) {
      console.log(commit)
      let fileSrc = this._vm.$baseURL + "/public/" + payload.url;
      axios({
        url: fileSrc,
        method: "GET",
        responseType: "blob",
      }).then((res) => {
        let FILE = window.URL.createObjectURL(new Blob([res.data]));

        let docUrl = document.createElement("a");
        docUrl.href = FILE;
        docUrl.setAttribute("download", "file."+payload.fileType);
        document.body.appendChild(docUrl);
        docUrl.click();
      });
    },
  },
});
