<template>
  <div
    class="base-input input-style"
  >
    <v-text-field
      :dark="false"
      class="w-100"
      color="lightdark"
      :type="type"
      outlined
      :label="label"
      :placeholder="placeholder"
      dense
      :readonly="readOnly"
      height="40px"
      max-height="40px"
      :rules="rules"
      :hide-details="hideDetails"
      :value="value"
      @input="$emit('input', $event)"
      @focus="$emit('focus', $event)"
      @blur="$emit('blur', $event)"
      :rounded="rounded"
      :prepend-inner-icon="icon"
      :background-color="background"
      autofocus
    >
    </v-text-field>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    value: {
      validator: () => true,
    },
    type: {
      type: String,
      default: "text",
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    background: {
      type: String,
      default: "",
    },
    rules: {
      type: Array,
      default: () => [(v) => !!v || "this field is require"],
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.input-style {
  .v-input__slot {
    fieldset {
      height: 45px;
      border: 1px solid #e2e5ed !important;
    }
    .v-input__append-inner {
      margin-top: 5px !important;
    }
    .v-text-field__slot {
      height: 40px !important;
    }
  }
    .error--text{
    fieldset{
      border: 1px solid #ff6161 !important;

    }
  }
}
</style>
