<template>
  <div class="base-input input-style base-select">
    <v-select
      :items="items"
      outlined
      :placeholder="placeholder"
      dense
      height="36px"
      max-height="36px"
      :hide-details="hideDetails"
      :item-value="itemValue"
      :item-text="itemText"
      :value="value"
      :rules="rules"
        :readonly="readOnly"
      :multiple="multiple"
      @input="$emit('input', $event)"
    >
      <template v-if="isSlot" v-slot:item="{ item }">
        <span class="font-14">    {{ item.name}}   ({{item.email }}) </span>
        <slot />
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: "",
    },
    itemValue: {
      type: String,
      default: "value",
    },
    itemText: {
      type: String,
      default: "title",
    },
    value: {
      validator: () => true,
    },
    rules: {
      type: Array,
      default: () => [(v) => !!v || ""],
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    isSlot: {
      type: Boolean,
      default: false,
    },
    readOnly:{
      type: Boolean,
      default: false
    }
  },
};
</script>

<style lang="scss">
.base-select {
  .v-input__slot {
    fieldset {
      height: 40px !important;
      border: 1px solid #e2e5ed !important;
    }
    .v-input__append-inner {
      margin-top: 5px !important;
    }
    .v-text-field__slot {
      height: 35px !important;
    }
  }

    .error--text{
    fieldset{
      border: 1px solid #ff6161 !important;

    }
  }
}
</style>
