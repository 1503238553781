import Vue from "vue";
import VueI18n from "vue-i18n";
import FR from "./fr";
import EN from "./en";
Vue.use(VueI18n);

export default new VueI18n({
  locale: "fr",
  fallbackLocale: "fr",
  messages: {
    en: EN,
    fr: FR,
  },
});
