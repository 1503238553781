<template>
  <div class="upload-attachment">
    <div class="cursor-pointer">
      <div class="upload-btn">
        <slot v-if="isSlot" />
        <v-icon v-if="!isSlot">mdi-home</v-icon>
      </div>
      <v-file-input
        accept="image/*"
        label="File input"
        @change="attachmentHandler"
        class="file-upload"
        hide-details="true"
      ></v-file-input>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    isSlot:{
      type: Boolean,
      default: false
    }
  },
  data: () => ({}),
  methods: {
    newImageHandler(file) {
      this.$emit("attachmentHandler", file);
    },
  },
};
</script>

<style lang="scss">
.upload-attachment {
  overflow: hidden;
  position: relative;

  .upload-btn {
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .file-upload {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 9;
      margin: 0px !important;
      padding: 0px !important;

      label {
        display: none !important;
      }
    }
  }
}
</style>
