import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import "vue-transitions-css";

Vue.use(Vuetify);
const opts = {
    theme: {
        defaultTheme: 'light',
        themes: {
            light: {
                primary: "#25a8e0",
                greybg: '#f9f8f8',
                darkblue: '#25a8e0',
                lightdark: '#2e2e30'

            },
        },
    },
};

export default new Vuetify(opts);
