import Vue from "vue";
import axios from "axios";
import store from "../store";
Vue.prototype.$baseURL = "https://backend.ingencia.odvertising.com";

axios.defaults.baseURL = `${Vue.prototype.$baseURL}/api`;

window.axios = axios;
Vue.prototype.$axios = axios;
axios.defaults.headers.common = {
  "x-auth-token": `${localStorage.getItem("token")}`,
};
export default async () => {
  // show loader in each request
  window.axios.interceptors.request.use(
    (confiq) => {
      if(confiq.method == 'post'){
        store.dispatch("loading", true);
      }
      return confiq;
    },
    (erorr) => {
      return erorr;
    }
  );

  window.axios.interceptors.response.use(
    function (response) {
      if(response.config.method == 'post'){
        store.dispatch("loading", false);
      }
      return response;
    },
    async function (error) {
        store.dispatch("loading", false);
      // response with status code > 200 is here
      return error.response.data;
    }
  );
};

// export default axios;
